<template>
  <div>
    <div class="write">请使用微信扫描二维码填写满意度评价</div>
    <div id="qrCodeUrl" ref="qrCodeUrl"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  components :{
    QRCode
  },
  data() {
    return {
      type: '',
      sex: '',
      name: '',
      id: '',
      url: '',
      domain: ''
    };
  },
  created(id, typeName, sex, name) {
    this.url = this.$url
    this.type = this.$route.query.typeName
    this.sex = this.$route.query.sex
    this.name = this.$route.query.name
    this.id = this.$route.query.id
    this.domain = this.$globalData.domain
    // console.log(this.id)
  },
  mounted() {
    // document.getElementById('qrCodeUrl').innerHTML = ''
    this.creatQrCode()
  },
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.url + 'survey?id=' + this.id + '&type=' + this.type +'&sex=' + this.sex +'&name=' +
            this.name + '&domain=' + encodeURIComponent(this.domain),
        // text: this.url + 'survey',
        // text: 'http://192.168.103/wggg/#/survey',
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode)
    },
  }
}
</script>

<style lang="scss" scoped>
#qrCodeUrl {
  display: inline-block;
  margin-left: 190px;
  margin-top: 340px;
  img {
    position:absolute;
    left:50%;
    top:50%;
    //width: 100px;
    //height: 100px;
    background-color: #fff; //设置白色背景色
    //padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
    //margin-left: 50%;
  }
}

.write {
  position:absolute;
  left:18%;
  top:270px;
  font-size: 30px;
  color: #00a2d3;
}

</style>
